// prefer default export if available

const preferDefault = m => m && m.default || m
exports.components = {
  "component---src-views-components-hero-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/944cf91a-75c8-5016-86d0-b0e6202a5e6c.js" /* webpackChunkName: "component---src-views-components-hero-index-tsx" */).then(preferDefault),
  "component---src-views-components-features-list-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/5cf1db39-37e1-5a56-bc31-6e16f7ff9126.js" /* webpackChunkName: "component---src-views-components-features-list-index-tsx" */).then(preferDefault),
  "component---src-views-components-infographics-with-phases-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/25279da9-c46d-5154-8cba-c75a88e4b754.js" /* webpackChunkName: "component---src-views-components-infographics-with-phases-index-tsx" */).then(preferDefault),
  "component---src-views-components-card-slider-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/9f3977c3-6296-5e9c-8cdd-a91ab7178c99.js" /* webpackChunkName: "component---src-views-components-card-slider-index-tsx" */).then(preferDefault),
  "component---src-views-components-half-content-half-widget-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/0ce2ea01-a8b6-5f9a-b360-c5b452acfb5c.js" /* webpackChunkName: "component---src-views-components-half-content-half-widget-index-tsx" */).then(preferDefault),
  "component---src-views-components-toggable-content-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/b68d8c59-b94e-5e4a-8c21-279dc5ca0ace.js" /* webpackChunkName: "component---src-views-components-toggable-content-index-tsx" */).then(preferDefault),
  "component---src-views-components-textual-content-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/4fdbd9af-198f-552a-956b-0be0fc113409.js" /* webpackChunkName: "component---src-views-components-textual-content-index-tsx" */).then(preferDefault),
  "component---src-views-components-icon-menu-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/e4bd8067-fb47-5a85-9f3d-0182a9224a24.js" /* webpackChunkName: "component---src-views-components-icon-menu-index-tsx" */).then(preferDefault),
  "component---src-views-components-media-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/39e4113c-5ab1-5850-84e6-4d95b8c97bbd.js" /* webpackChunkName: "component---src-views-components-media-index-tsx" */).then(preferDefault),
  "component---src-views-components-simple-card-list-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/e1613ba1-3db0-5b38-bf41-9d0fbce7db6f.js" /* webpackChunkName: "component---src-views-components-simple-card-list-index-tsx" */).then(preferDefault),
  "component---src-views-components-icon-box-grid-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/7c0d0a3e-5947-5852-9461-cd5f4324d83b.js" /* webpackChunkName: "component---src-views-components-icon-box-grid-index-tsx" */).then(preferDefault),
  "component---src-views-components-columns-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/4987b3a8-7001-5721-b618-deafcb658810.js" /* webpackChunkName: "component---src-views-components-columns-index-tsx" */).then(preferDefault),
  "component---src-views-components-timeline-with-images-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/79229d75-cfb6-5640-92c2-7a8ffc363ece.js" /* webpackChunkName: "component---src-views-components-timeline-with-images-index-tsx" */).then(preferDefault),
  "component---src-views-components-media-text-box-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/3e5706ef-88e2-5736-ab63-d57cfdb4ce65.js" /* webpackChunkName: "component---src-views-components-media-text-box-index-tsx" */).then(preferDefault),
  "component---src-views-components-our-team-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/70168bb8-e447-5e09-a4ea-af36862f6daa.js" /* webpackChunkName: "component---src-views-components-our-team-index-tsx" */).then(preferDefault),
  "component---src-views-components-vertical-scroll-slides-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/38289a02-1a1a-562f-ac54-75d44449ebac.js" /* webpackChunkName: "component---src-views-components-vertical-scroll-slides-index-tsx" */).then(preferDefault),
  "component---src-views-components-faq-list-section-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/fcd81789-47be-5533-b0fa-ff289394db6a.js" /* webpackChunkName: "component---src-views-components-faq-list-section-index-tsx" */).then(preferDefault),
  "component---src-views-components-form-with-media-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/9d0727a3-5753-598a-ba11-51c8d8da87f1.js" /* webpackChunkName: "component---src-views-components-form-with-media-index-tsx" */).then(preferDefault),
  "component---src-views-components-help-center-flow-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/06b4bc59-a08b-58cf-a7e3-dab2e2ff5aa4.js" /* webpackChunkName: "component---src-views-components-help-center-flow-index-tsx" */).then(preferDefault),
  "component---src-views-components-faq-section-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/5a59d6e9-c52b-55cc-9f69-efc1850e7e54.js" /* webpackChunkName: "component---src-views-components-faq-section-index-tsx" */).then(preferDefault),
  "component---src-views-components-video-section-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/d972a57b-54df-59f0-ae29-f93e2a2018e5.js" /* webpackChunkName: "component---src-views-components-video-section-index-tsx" */).then(preferDefault),
  "component---src-views-components-job-listing-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/4ac23e33-b93e-5578-914a-00f993fe3e18.js" /* webpackChunkName: "component---src-views-components-job-listing-index-tsx" */).then(preferDefault),
  "component---src-views-components-media-slider-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/3fbc4655-f1c7-5f05-9b06-8ff809f35b98.js" /* webpackChunkName: "component---src-views-components-media-slider-index-tsx" */).then(preferDefault),
  "component---src-views-components-sitemap-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/9fce5e90-fb54-5763-81cb-6436b6758324.js" /* webpackChunkName: "component---src-views-components-sitemap-index-tsx" */).then(preferDefault),
  "component---src-views-components-track-shipment-results-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/a878b49e-4c95-557f-a338-e444357fa206.js" /* webpackChunkName: "component---src-views-components-track-shipment-results-index-tsx" */).then(preferDefault),
  "component---src-views-components-faqs-contact-details-index-tsx": () => import("/codebuild/output/src692715775/src/.cache/gatsby-plugin-graphql-component/components/36756fd8-84f0-5fcc-9baf-37a6b504d6c7.js" /* webpackChunkName: "component---src-views-components-faqs-contact-details-index-tsx" */).then(preferDefault)
}

